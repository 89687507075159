import React, { useRef, useState, useEffect, Suspense } from 'react';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import { useHistory } from 'react-router-dom';
import { endpoints } from '../../../config/connections';
import AppLoader from '@usb-ui-tools/micro-app-loader/dist/cjs/index.js';
import useUserRole from '../../components/Hooks/use-UserRole'

const SessionHeader = ({
  currentToken,
  onIdleTimeoutLogoutSuccess,
  extendSessionSuccess,
  onIdleTimeoutLogoutFailure,
  refreshTimeCount,
  onSuccessChallenge,
}) => {
  const [isIdle, setIsIdle] = useState(true);
  const [iFrameIdleTime, setiFrameIdleTime] = useState(null);
  const [extendRequest, setextendRequest] = useState(null);
  const [logoutClick, setlogoutClick] = useState(false);

  const history = useHistory();

  const ref = useRef('idleTimeout');
  const userCheck = useUserRole();

  const configSettings = {
    transmitConfigs: {
      URL: endpoints.transmit,
      APP_ID: 'cdc_web',
      policyIds: {
        EXTEND_USER_SESSION: 'extend_user_session',
        USER_LOGOUT: 'user_logout',
      },
      policyParams: {
        routingKey: endpoints.authRoutingKey,
        appId: 'CDC',
        tenantId: 'USB',
        channelId: 'web',
        appVersion: '1',
      },
    },
  };

  const manageUserSession = () => {
    if (typeof window !== 'undefined') {
      let element = document.getElementById('manageuser-app');
      if (element) {
        window.addEventListener(
          'message',
          (e) => {
            element = document.getElementById('manageuser-app');
            if (e.data.servicingUserActive) {
              setextendRequest(e.data.servicingUserActive);
            }
            if (e.data.servicingUserIdleTime !== undefined) {
              setiFrameIdleTime(e.data.servicingUserIdleTime);
            }
          },
          false
        );
      }
    }
  };

  const parseJwt = (tok) => {
    const base64Url = tok !== null ? tok.split('.')[1] : '';
    const base64 = decodeURIComponent(
      atob(base64Url)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(base64);
  };

  const checkIfSessionExists = () => {
    let parsedToken;
    if (sessionStorage.getItem('tok') !== null) {
      parsedToken = parseJwt(sessionStorage.getItem('tok')).iat;
    } else {
      parsedToken = null;
    }
    return parsedToken;
  };

  const getCurrentExtendTime = () => {
    const currentIat =
      typeof sessionStorage !== 'undefined' ? checkIfSessionExists() : null;
    const sessionTimer = 780000;
    let refreshTime = currentIat * 1000 + sessionTimer - Date.now();
    if (refreshTime <= 60000) {
      refreshTime = 60000;
    }
    return refreshTime;
  };

  useEffect(() => {
    manageUserSession();
  }, []);

  return (
    <>
      <USBGrid>
        <USBColumn largeSpan={2} />
        <USBColumn largeSpan={12}>
          {currentToken && (
            <>
              <Suspense fallback={<div>Loading...</div>}>

              <AppLoader
                  elementRef={ React.createRef()}
                  id="auth-extsession"
                  manifestPath={endpoints.manifestPathTimeout}
                  namespace="USBAuthExtSession"
                  isDomainPrefixedToManifestPath={false}
                  appProps={{
                    configSettings:configSettings,
                    currentToken:currentToken,
                    onTransmitAuthorizationFailure:onIdleTimeoutLogoutFailure,
                    onTransmitAuthorizationSuccess:onSuccessChallenge,
                    timerCount:10000, // ExtendSessionTimer
                    loadIdleModule:isIdle,
                    idleTimer:10000
              }}
            />

              </Suspense>
            </>
          )}
          
        </USBColumn>
        <USBColumn largeSpan={2} />
      </USBGrid>
    </>
  );
};

SessionHeader.propTypes = {};

export default SessionHeader;
